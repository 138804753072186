import React from 'react'
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Navigate, Route, Routes } from 'react-router-dom';
import PrivateRoutes from './Utilities/PrivateRoutes';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Pagenotfound from './components/Pagenotfound';
import RedirectRoutes from './Utilities/RedirectRoutes';
import AddBid from './components/AddBid';
import MyProfile from './components/MyProfile';
import TransporterAddSuccess from './components/TransporterAddSuccess';
import TransporterUploadDocuments from './components/TransporterUploadDocuments';
import TransporterContactDetails from './components/TransporterContactDetails';
import TransporterBankDetails from './components/TransporterBankDetails';
import TransporterDetail from './components/TransporterDetail';
import TimeDate from './components/timeDate';
import TransportList from './components/TransportList';
import LandingPage from './components/LandingPage';

function App() {
  return (
    <div>
        <ToastContainer />
      <>
        <Routes>
          <Route element={<RedirectRoutes />}>
            <Route path='/login' element={<Login />} />
            <Route path='/' element={<Navigate to={'/login'} />} />
          </Route>
          <Route element={<PrivateRoutes />}>
            <Route path="/dashboard" element={<LandingPage />} />
            <Route path="/home" element={<Dashboard />} />
            <Route path="/addBid" element={<AddBid />} />
            <Route path="/datepicker" element={<TimeDate />} />
            <Route path="/myprofile" element={<MyProfile />} />
            <Route path="/transporterdetail" element={<TransporterDetail />} />
            <Route path="/transportdocupdate/:id" element={<TransporterUploadDocuments />} />
            <Route path="/transportercontactdetails/:id" element={<TransporterContactDetails />} />
            <Route path="/transporterbankdetails/:id" element={<TransporterBankDetails />} />
            <Route path="/transporter" element={<TransportList />} />
            <Route path="/success" element={<TransporterAddSuccess />} />
          </Route>
          <Route path="*" element={<Pagenotfound />} />
        </Routes>
      </>
    </div>
  );
}

export default App;
