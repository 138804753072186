import React, {  useEffect } from 'react'
import {Navigate,  Outlet,useNavigate } from 'react-router-dom';
import NavHead from '../components/NavHead';
import { Footer } from '../components/Footer';
const PrivateRoutes = () => {
    let navigate = useNavigate();
        useEffect(() => {
            if(!localStorage.getItem("adroit_token")) {
                navigate("/")
            }
        }, []);
     let auth = { token: false }
    if (localStorage.getItem("adroit_token") !== null) {
        auth.token = true
    }
    else
        auth.token = false
    return (
        <>
        {
        auth.token ? 
        <>
        <NavHead />
        <Outlet />
        <Footer />
        </>
         : <Navigate to={'/'} />
        }   
        </>
    )
}
export default PrivateRoutes