import React, { useState } from 'react';
import { Navbar, Container, Nav, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../assets/img/logo.png'
import { CgProfile } from 'react-icons/cg';
import Dropdown from 'react-bootstrap/Dropdown';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { handleError } from "../Utilities/HandleError";

const AppNavbar = () => {

  let navigate = useNavigate();
  const onUserLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to logout!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#0d6efd",
      confirmButtonText: "Yes ,logout!",
    }).then((result) => {
      if (result.isConfirmed) {
        let url = process.env.REACT_APP_BASEURL + "logout_all/";
        const config = {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-type": "application/json",
            "Authorization": `bearer ` + localStorage.getItem('adroit_token'),
          },
        };
        axios.get(url, config).then((res) => {
          console.log("User Logout.")
          localStorage.clear();
          navigate("/");
          toast.success("Admin Logout!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            theme: "colored",
          });
        }).catch((err) => {
          const getErrRes = handleError(err);
        if (getErrRes === 401) {
          localStorage.clear();
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            theme: "colored",
          });
        } else {
          toast.error(getErrRes ? getErrRes : "Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            theme: "colored",
          });
        }
        })
      }
    }).catch((err) => {
      const getErrRes = handleError(err);
        if (getErrRes === 401) {
          localStorage.clear();
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            theme: "colored",
          });
        } else {
          toast.error(getErrRes ? getErrRes : "Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            theme: "colored",
          });
        }
    });
  }
  return (
    <>
      <Navbar expand="lg" className="sticky-top shadow one" >
        <Container fluid className='margin-top: -10px;
         background-color: #fff'>
          <Navbar.Brand>
            <Image src={logo} alt="Company Logo" className="logo ms-0" onClick={() => { navigate('/dashboard')}} style={{cursor:"pointer"}}/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto mt-3">
              <CgProfile className='fs-2 mt-1 me-2' />
              {/* <span className='pt-2 me-2'>Ritesh </span> */}
              <Dropdown>
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                  Admin
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={()=>{navigate('/myprofile')}}>My Profile</Dropdown.Item>
                  <Dropdown.Item onClick={()=>{navigate('/transporterdetail')}}>Add Transporter</Dropdown.Item>
                  <Dropdown.Item onClick={()=>{navigate('/addBid')}}>Add Bid</Dropdown.Item>
                  <Dropdown.Item onClick={() => { navigate('/transporter') }}>Transporter List</Dropdown.Item>
                  <Dropdown.Item onClick={onUserLogout} >Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div className="vr m-2 mb-3"></div>
              <Nav.Link href="#notifications">
                <li className="nav-item me-3" style={{ width: 40 }}>
                  <Link href="#" className="nav-link nav-icon iconClass">
                    <i className="fas fa-bell"></i>
                    <span className="badge badge-light">2</span>
                  </Link>
                </li>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
export default AppNavbar;
