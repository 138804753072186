import React, { useEffect, useState } from "react";
import { Row, Col, Button } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import { toast, ToastContainer } from "react-toastify";
import Accordion from 'react-bootstrap/Accordion';
import Multiselect from 'multiselect-react-dropdown';
import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from "moment";
import axios from 'axios';
import { json, useNavigate } from "react-router-dom";

const AddBid = () => {
  let navigate = useNavigate()
  const [loading, setLoading] = useState(false);
   const [vehicleList, setVehicleList ]= useState([]);
  const [groupList, setGroupList] = useState([]);
  const [time, setTime] = useState(new Date());
  const [maximumDate, setMaximumDate] = useState(new Date());
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [groupError, setGroupError] = useState('');
  const [addData, setAddData] = useState({
    productName: '', productNameerr: '',
    weight:'', weighterr:'',
    height:'', heighterr:'',
    length:'', lengtherr:'',
    dealerName: '', dealerNameerr:'',
    doNumber: '', doNumbererr:'',
    locationName: '', locationNameerr:'',
    mobileNumber: '', mobileNumbererr:'',
    state: '', stateerr:'',
    description: '', descriptionerr:'',
    city: '', cityerr:'',
    pincode: '', pincodeerr:'',
    addresserr:'',
    publicIdErr:''
  })  
  const currentDate = moment();
 const [startDate, setStartDate] = useState(new Date());
  const [endTime, setEndTime] = useState(moment());
  const [maxTime, setMaxTime] = useState(moment(currentDate).add(8, 'hours'));
  // const [endTime, setEndTime] = useState(moment().add('hour').format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
  const [truckArrival, setTruckArrival] = useState(moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
  const [dateValue, setDateValue] = useState(moment())
  // const maxTime = moment(currentDate).add(8, 'hours');
  const [publicId, setPublicId] = useState("")

  // { / *******************ADDRESS 1 States Start********************/ }
  // const [dealerName, setDealerName] = useState("")
  // const [doNumber, setDoNumber] = useState("")
  // const [locationName, setLocationName] = useState("")
  // const [mobileNumber, setMobileNumber] = useState("")
  // const [state, setState] = useState("")
  // const [description, setDescription] = useState("")
  // const [city, setCity] = useState("")
  // const [pincode, setPincode] = useState("")
;
  // { / *******************ADDRESS 1 States End********************/ }

  // { / *******************ADDRESS 2 States Start********************/ }
  const [dealerName2, setDealerName2] = useState("")
  const [doNumber2, setDoNumber2] = useState("")
  const [locationName2, setLocationName2] = useState("")
  const [mobileNumber2, setMobileNumber2] = useState("")
  const [state2, setState2] = useState("")
  const [description2, setDescription2] = useState("")
  const [city2, setCity2] = useState("")
  const [pincode2, setPincode2] = useState("")
  // { / *******************ADDRESS 2 States End********************/ }


  // { / *******************ADDRESS 3 States Start********************/ }
  const [dealerName3, setDealerName3] = useState("")
  const [doNumber3, setDoNumber3] = useState("")
  const [locationName3, setLocationName3] = useState("")
  const [mobileNumber3, setMobileNumber3] = useState("")
  const [state3, setState3] = useState("")
  const [description3, setDescription3] = useState("")
  const [city3, setCity3] = useState("")
  const [pincode3, setPincode3] = useState("")
  // { / *******************ADDRESS 3 States End********************/ }


  // { / *******************ADDRESS 4 States Start********************/ }
  const [dealerName4, setDealerName4] = useState("")
  const [doNumber4, setDoNumber4] = useState("")
  const [locationName4, setLocationName4] = useState("")
  const [mobileNumber4, setMobileNumber4] = useState("")
  const [state4, setState4] = useState("")
  const [description4, setDescription4] = useState("")
  const [city4, setCity4] = useState("")
  const [pincode4, setPincode4] = useState("")
  // { / *******************ADDRESS 4 States End********************/ }

  // { / *******************ADDRESS 5 States Start********************/ }
  const [dealerName5, setDealerName5] = useState("")
  const [doNumber5, setDoNumber5] = useState("")
  const [locationName5, setLocationName5] = useState("")
  const [mobileNumber5, setMobileNumber5] = useState("")
  const [state5, setState5] = useState("")
  const [description5, setDescription5] = useState("")
  const [city5, setCity5] = useState("")
  const [pincode5, setPincode5] = useState("")
    // { / *******************ADDRESS 5 States End********************/ }
  // { / *******************ADDRESS 6 States Start********************/ }
  const [dealerName6, setDealerName6] = useState("")
  const [doNumber6, setDoNumber6] = useState("")
  const [locationName6, setLocationName6] = useState("")
  const [mobileNumber6, setMobileNumber6] = useState("")
  const [state6, setState6] = useState("")
  const [description6, setDescription6] = useState("")
  const [city6, setCity6] = useState("")
  const [pincode6, setPincode6] = useState("")
    // { / *******************ADDRESS 6 States End********************/ }
  const endTimeutcTimestamp = endTime;
    const endTimeistTimestamp = moment();

  useEffect(() => {
    
    vehicleListData()
    groupListData()
   


  }, [])
  const calculateMaximumDate = () => {
    setEndTime((prevEndTime) => {
      if (prevEndTime.isAfter(maxTime)) {
        return maxTime;
      }
      return prevEndTime;
    });
  };

  useEffect(() => {
    calculateMaximumDate();
  }, [maxTime]);
  const updateMaxTime = () => {
    setMaxTime(moment(currentDate).add(8, 'hours'));
  };
  useEffect(() => {
    const interval = setInterval(updateMaxTime, 1000); // Update maxTime every second
    return () => clearInterval(interval);
  }, [currentDate]);
  const isValidDate = (current) => {
    return current.isAfter(currentDate, 'day');
  };

  const isValidTime = (current) => {
    const now = moment();
    return current.isSameOrAfter(now, 'minute') && current.isBefore(maxTime);
  };
  const timeConstraints = {
    hours: { min: moment().hour(), max: maxTime.hour(), step: 1 },
    minutes: { min: moment().minute(), max: maxTime.minute(), step: 1 },
    seconds: { min: moment().second(), max: maxTime.second(), step: 1 },
    milliseconds: { min: moment().millisecond(), max: maxTime.millisecond(), step: 100 },
  };
  const handleGroupSelect = (selectedList, selectedItem) => {
    setSelectedGroups(selectedList.map((item) => item.value));
    setGroupError(''); // Clear the groupError when a group is selected
  };

  const handleGroupRemove = (selectedList, removedItem) => {
    setSelectedGroups(selectedList.map((item) => item.value));
    if (selectedList.length === 0) {
      setGroupError('Please select at least one group.');
    } else {
      setGroupError(''); // Clear the groupError when a group is removed and at least one group is still selected
    }
  };

  const vehicleListData = () => {
    setLoading(true);
    let url = process.env.REACT_APP_BASEURL + 'vehicle/';
    console.log(url)
    const config = {
      headers: {
        "Authorization": `bearer ` + localStorage.getItem('adroit_token'),
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
      }
    };
    axios.get(url, config)
      .then(res => {
        setVehicleList(res.data.data)
        console.log(res.data.data, "list")
    
      }
      ).catch(err => {

    console.log(err)
      })
  }
  const handleSubmit = (e) => {

    e.preventDefault()
    let error = { status: 0, productName: '', post: '',group:'' }
    if (addData.productName === '') { error.productName = 'Please enter product name'; error.status = 1 } else { error.productName = '' }
    if (addData.weight === '') { error.weight = 'Please enter weight'; error.status = 1 } else { error.weight = '' }
    if (addData.height === '') { error.height = 'Please enter height'; error.status = 1 } else { error.height = '' }
    if (addData.length === '') { error.length = 'Please enter length'; error.status = 1 } else { error.length = '' }
    if (addData.dealerName === '') { error.dealerName = 'Please enter dealer name'; error.status = 1 } else { error.dealerName = '' }
    if (addData.locationName === '') { error.locationName = 'Please enter address'; error.status = 1 } else { error.locationName = '' }
    if (addData.mobileNumber === '') { error.mobileNumber = 'Please enter mobile number'; error.status = 1 } else { error.mobileNumber = '' }
    if (addData.state === '') { error.state = 'Please enter state'; error.status = 1 } else { error.state = '' }
    if (addData.description === '') { error.description = 'Please enter description'; error.status = 1 } else { error.description = '' }
    if (addData.city === '') { error.city = 'Please enter city'; error.status = 1 } else { error.city = '' }
    if (addData.pincode === '') { error.pincode = 'Please enter pincode'; error.status = 1 } else { error.pincode = '' }
    if (addData.doNumber === '') { error.doNumber = 'Please DO number'; error.status = 1 } else { error.doNumber = '' }
    if (addData.length === '') { error.length = 'Please enter length'; error.status = 1 } else { error.length = '' }

    setAddData({ ...addData, productNameerr: error.productName, addresserr: 'Delivery address is required', publicIdErr:'Vehicle list is required' ,doNumbererr: error.doNumber, weighterr: error.weight, heighterr: error.height, lengtherr: error.length, dealerNameerr: error.dealerName, locationNameerr: error.locationName, stateerr: error.state, descriptionerr: error.description, cityerr: error.city, pincodeerr: error.pincode, })
    if (selectedGroups.length === 0) {
      setGroupError('Please select at least one group.');
    } else {
      setGroupError(''); // Clear the error message if groups are selected
    }
    if (error.status === 0) {
      if (selectedGroups.length === 0) {
        setGroupError('Please select at least one group.');
        error.group = 'Please select at least one group.';
      } else {
        setGroupError(''); // Clear the groupError if there are selected groups
        error.group = '';
      }

      // If any group error or other errors are present, prevent the API call
      if (error.group || error.status) {
        return;
      }

      setLoading(true)
      const AddressArr = []
      const AddressObj1 = {
        name_of_location: addData.locationName,
        alternate_mobile: addData.mobileNumber,
        pincode: addData.pincode,
        description: addData.description,
        city: addData.city,
        state: addData.state,
        do_number: addData.doNumber,
        dealer_name: addData.dealerName
      }
      const AddressObj2 = {
        name_of_location: locationName2,
        alternate_mobile: mobileNumber2,
        pincode: pincode2,
        description: description2,
        city: city2,
        state: state2,
        do_number: doNumber2,
        dealer_name: dealerName2
      }
      const AddressObj3 = {
        name_of_location: locationName3,
        alternate_mobile: mobileNumber3,
        pincode: pincode3,
        description: description3,
        city: city3,
        state: state3,
        do_number: doNumber3,
        dealer_name: dealerName3
      }
      const AddressObj4 = {
        name_of_location: locationName4,
        alternate_mobile: mobileNumber4,
        pincode: pincode4,
        description: description4,
        city: city4,
        state: state4,
        do_number: doNumber4,
        dealer_name: dealerName4
      }
      const AddressObj5 = {
        name_of_location: locationName5,
        alternate_mobile: mobileNumber5,
        pincode: pincode5,
        description: description5,
        city: city5,
        state: state5,
        do_number: doNumber5,
        dealer_name: dealerName5
      }
      const AddressObj6 = {
        name_of_location: locationName6,
        alternate_mobile: mobileNumber6,
        pincode: pincode6,
        description: description6,
        city: city6,
        state: state6,
        do_number: doNumber6,
        dealer_name: dealerName6
      }
      if (addData.locationName.length > 0) {
        AddressArr.push(AddressObj1)
      }
      if (locationName2.length > 0) {
        AddressArr.push(AddressObj2)
      }
      if (locationName3.length > 0) {
        AddressArr.push(AddressObj3)
      }
      if (locationName4.length > 0) {
        AddressArr.push(AddressObj4)
      }
      if (locationName5.length > 0) {
        AddressArr.push(AddressObj5)
      }
      if (locationName6.length > 0) {
        AddressArr.push(AddressObj6)
      }
    console.log("AddressArr", AddressArr);
      const body = {
        location: { latitude: 23.36, longitude: 75.36 },
        truck_arrival_time: truckArrival,
        unloading_points: AddressArr,
        product_name: addData.productName,
        weight: addData.weight,
        vehicle: publicId,
        height: addData.height,
        length: addData.length,
        start_time: startDate,
        end_time: endTime,
        group_id: selectedGroups ,
      }
console.log(body,"body")
console.log(endTime, "end time")
      let url = process.env.REACT_APP_BASEURL + `bids/`     
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "application/json",
          "Authorization": `bearer ` + localStorage.getItem('adroit_token'),

        }
      };
      console.log("jgjhg")
      // console.log("bodyy", formData)
      axios.post(url, body, config)
        .then(res => {
          setLoading(false)
          console.log(res.data, "kjhkjh")
          toast.success("Bid Created Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            theme: "colored",
          })
          navigate('/home')
        })
        .catch(err => {
          setLoading(false)
          console.log(err, "errorrr")

          toast.error(`${err.message}`, {
            autoClose: 1000,
            theme: "colored",
          });
        })
    }

  }

 
  const groupListData = () => {
    setLoading(true);

    let url = process.env.REACT_APP_BASEURL + `publish/group/`     
    console.log(url)
    const config = {
      headers: {
        "Authorization": `bearer ` + localStorage.getItem('adroit_token'),
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
      }
    };
    axios.get(url, config)
      .then(res => {
        setLoading(false)
        setGroupList(res.data.data)
        console.log(res.data, "list")
        

      }
      ).catch(err => {
        setLoading(false)
        console.log(err)
      })
  }


  const handleDateChange = (date) => {
    // Format the selected date and time in the required format before setting it to the state
    const formattedDate = moment(date).toISOString();
    setEndTime(formattedDate);
    console.log("Formatted Date:", formattedDate);

  };
  const handleDateChange2 = (date) => {
    // Format the selected date and time in the required format before setting it to the state
    const formattedDate = moment(date).toISOString();
    setTruckArrival(formattedDate);
    console.log("Formatted Date:", formattedDate);

  };

  return (
    <>
      <ToastContainer></ToastContainer>

      <div className=" text-center mt-5">
        <h2 className="title">Add Bid Detail</h2>


      </div>
      <div>

      </div>
      <Row className="d-flex justify-content-center p-0 m-0  mt-3 " style={{ marginBottom: '80px' }}>

        <Col lg={7} className="" >
          <Card style={{ boxShadow: '1px 2px 9px 0px #808080db' }} >
            <Card.Body className='text-left'>
              <Form onSubmit={handleSubmit}>
                {/* <Dropdown >
                  <Dropdown.Toggle id="dropdown-basic" className=" border border-secondary" style={{ paddingBottom: '9px', background: '#fff', color: 'black', minWidth: '210px' }}>
                    {categoryName === '' ? "All Categories" : categoryName}
                  </Dropdown.Toggle>
                  {categoryList.length === 0 ? <Dropdown.Menu><Dropdown.Item disabled>No Data Available</Dropdown.Item></Dropdown.Menu> : <Dropdown.Menu style={{ height: '30vh', overflowX: "hidden" }}>
                    <Dropdown.Item onClick={() => { setShowAllProdcut(true); setCategoryName(''); setPublicId(''); setCategory('') }}>All Categories</Dropdown.Item>
                    {
                      categoryList.map((item) => {
                        return (
                          <Dropdown.Item onClick={() => { setPublicId(item.public_id); setCategory(item.name); setCategoryName(item.name); productListData(item.public_id); setShowAllProdcut(false) }} >{item.name}</Dropdown.Item>
                        )
                      })
                    }

                  </Dropdown.Menu>}
                </Dropdown> */}
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label style={{ fontSize: '14px',  fontWeight: 'bold' }}>Select Vehicle</Form.Label>

                      {vehicleList.length === 0 ? <Form.Select><option style={{padding:'15px'}}>Not Found</option></Form.Select> : <Form.Select onChange={(event) => {setPublicId(event.target.value); console.log(event.target.value); }}  style={{ padding: '15px' }} >
                        <option disabled >Vehicle List</option>
                      
                       {vehicleList.map((item) => {
                    
                         return (<option key={item.public_id} value={item.public_id} >{item.name}</option>)
                       })
                       }
                      </Form.Select>}
                      {publicId === '' ? <small className="text-danger">{addData.publicIdErr}</small> : null}
                    </Form.Group>
              
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label style={{ fontSize: '14px', fontWeight: 'bold'  }}>Product Name</Form.Label>
                      <Form.Control type="text" placeholder="Enter Product name" style={{ padding: '15px' }}
                        defaultValue={addData.productName} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, productName: e.target.value, productNameerr: '' }) : setAddData({ ...addData, productName: '', productNameerr: 'Product name  is required.' }) }} />
                      <small className="text-danger">{addData.productNameerr}</small>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mt-4'>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label style={{ fontSize: '14px', fontWeight: 'bold'  }}>Weight in tons (approx)</Form.Label>
                      <Form.Control type="number" placeholder="Enter Weight in tons (approx)" style={{ padding: '15px' }}
                        defaultValue={addData.weight} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, weight: e.target.value, weighterr: '' }) : setAddData({ ...addData, weight: '', weighterr: 'Weight  is required.' }) }} />
                      <small className="text-danger">{addData.weighterr}</small>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label style={{ fontSize: '14px', fontWeight: 'bold'  }}>Height in feet (approx)</Form.Label>
                      <Form.Control type="number" placeholder="Enter Height in feet (approx)" style={{ padding: '15px' }}
                        defaultValue={addData.height} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, height: e.target.value, heighterr: '' }) : setAddData({ ...addData, height: '', heighterr: 'Height  is required.' }) }} />
                      <small className="text-danger">{addData.heighterr}</small>
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label style={{ fontSize: '14px', fontWeight: 'bold'  }}>Length in feet (approx)</Form.Label>
                      <Form.Control type="number" placeholder="Enter Length in feet (approx)" style={{ padding: '15px' }}
                        defaultValue={addData.length} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, length: e.target.value, lengtherr: '' }) : setAddData({ ...addData, length: '', lengtherr: 'Length  is required.' }) }} />
                      <small className="text-danger">{addData.lengtherr}</small>
                    </Form.Group>
                  </Col>
                </Row>

                <Accordion >
                  <Row className='mt-4'>
                    <Col>
                      <Accordion.Item eventKey="0" >
                        <Accordion.Header style={{ fontSize: '14px', fontWeight: 'bold'  }}>Delivery Address 1</Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-3">

                            <Form.Control type="text" placeholder="Dealer Name"
                              defaultValue={addData.dealerName} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, dealerName: e.target.value, dealerNameerr: '' }) : setAddData({ ...addData, dealerName: '', dealerNameerr: 'dealer name  is required.' }) }} />
                            <small className="text-danger">{addData.dealerNameerr}</small>
                           
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="DO Number / Details"
                              defaultValue={addData.doNumber} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, doNumber: e.target.value, doNumbererr: '' }) : setAddData({ ...addData, doNumber: '', doNumbererr: 'Do number is required.' }) }} />
                            <small className="text-danger">{addData.doNumbererr}</small>
                           
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="Full Address"
                              defaultValue={addData.locationName} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, locationName: e.target.value, locationNameerr: '' }) : setAddData({ ...addData, locationName: '', locationNameerr: 'Address is required.' }) }} />
                            <small className="text-danger">{addData.locationNameerr}</small>
                            
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="City"
                              defaultValue={addData.city} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, city: e.target.value, cityerr: '' }) : setAddData({ ...addData, city: '', cityerr: 'City is required.' }) }} />
                            <small className="text-danger">{addData.cityerr}</small>
                           
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="number" placeholder="Pincode"
                              maxLength={6} value={addData.pincode} onChange={(e) => { e.target.value !== '' || e.target.value.length > e.target.maxLength ? setAddData({ ...addData, pincode: e.target.value.slice(0, e.target.maxLength), pincodeerr: '' }) : setAddData({ ...addData, pincode: '', pincodeerr: "Pincode number is required" }) }} />
                            <small className="text-danger">{addData.pincodeerr}</small>
                            
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="State"
                              defaultValue={addData.state} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, state: e.target.value, stateerr: '' }) : setAddData({ ...addData, state: '', stateerr: 'state is required.' }) }} />
                            <small className="text-danger">{addData.stateerr}</small>
                            
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="number" placeholder="Mobile Number"
                              maxLength={10} value={addData.mobileNumber} onChange={(e) => { e.target.value !== '' || e.target.value.length > e.target.maxLength ? setAddData({ ...addData, mobileNumber: e.target.value.slice(0, e.target.maxLength), mobileNumbererr: '' }) : setAddData({ ...addData, mobileNumber: '', mobileNumbererr: "Mobile number is required" }) }} />
                            <small className="text-danger">{addData.mobileNumbererr}</small>
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="Remark"
                              defaultValue={addData.description} onChange={(e) => { e.target.value !== '' ? setAddData({ ...addData, description: e.target.value, descriptionerr: '' }) : setAddData({ ...addData, description: '', descriptionerr: 'Description is required.' }) }} />
                            <small className="text-danger">{addData.descriptionerr}</small>
                       
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>
                      {addData.description === ''?  <small className="text-danger">{addData.addresserr}</small>:null}
                    </Col>
                    <Col>
                      <Accordion.Item eventKey="1" style={{ borderTop: '1px solid #DEE2E6', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                        <Accordion.Header>Delivery Address 2 (optional)</Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-3">

                            <Form.Control type="text" placeholder="Dealer Name"
                              defaultValue={dealerName2} onChange={(e) => { setDealerName2(e.target.value) }} />

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="DO Number / Details"
                              defaultValue={doNumber2} onChange={(e) => { setDoNumber2(e.target.value) }} />

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="Full Address"
                              defaultValue={locationName2} onChange={(e) => { setLocationName2(e.target.value) }} />

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="City"
                              defaultValue={city2} onChange={(e) => { setCity2(e.target.value) }} />

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="Pincode"
                              defaultValue={pincode2} onChange={(e) => { setPincode2(e.target.value) }} />

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="State"
                              defaultValue={state2} onChange={(e) => { setState2(e.target.value) }} />

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="number" placeholder="Mobile Number"
                              defaultValue={mobileNumber2} onChange={(e) => { setMobileNumber2(e.target.value) }} />

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="Remark"
                              defaultValue={description2} onChange={(e) => { setDescription2(e.target.value) }} />
                          
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Col>
                  </Row>
                  <Row className='mt-4'>
                    <Col>
                      <Accordion.Item eventKey="2" className='mt-4' style={{ borderTop: '1px solid #DEE2E6', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                        <Accordion.Header>Delivery Address 3 (optional)</Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-3">

                            <Form.Control type="text" placeholder="Dealer Name"
                              defaultValue={dealerName3} onChange={(e) => { setDealerName3(e.target.value) }} />

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="DO Number / Details"
                              defaultValue={doNumber3} onChange={(e) => { setDoNumber3(e.target.value) }} />

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="Full Address"
                              defaultValue={locationName3} onChange={(e) => { setLocationName3(e.target.value) }} />

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="City"
                              defaultValue={city3} onChange={(e) => { setCity3(e.target.value) }} />

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="Pincode"
                              defaultValue={pincode3} onChange={(e) => { setPincode3(e.target.value) }} />

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="State"
                              defaultValue={state3} onChange={(e) => { setState3(e.target.value) }} />

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="Mobile Number"
                              defaultValue={mobileNumber3} onChange={(e) => { setMobileNumber3(e.target.value) }} />

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="Remark"
                              defaultValue={description3} onChange={(e) => { setDescription3(e.target.value) }} />
                          
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Col>
                    <Col>
                      <Accordion.Item eventKey="3" className='mt-4' style={{ borderTop: '1px solid #DEE2E6', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                        <Accordion.Header>Delivery Address 4 (optional)</Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-3">

                            <Form.Control type="text" placeholder="Dealer Name"
                              defaultValue={dealerName4} onChange={(e) => { setDealerName4(e.target.value) }} />

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="DO Number / Details"
                              defaultValue={doNumber4} onChange={(e) => { setDoNumber4(e.target.value) }} />

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="Full Address"
                              defaultValue={locationName4} onChange={(e) => { setLocationName4(e.target.value) }} />

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="City"
                              defaultValue={city4} onChange={(e) => { setCity4(e.target.value) }} />

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="Pincode"
                              defaultValue={pincode4} onChange={(e) => { setPincode4(e.target.value) }} />

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="State"
                              defaultValue={state4} onChange={(e) => { setState4(e.target.value) }} />

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="Mobile Number"
                              defaultValue={mobileNumber4} onChange={(e) => { setMobileNumber4(e.target.value) }} />

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="Remark"
                              defaultValue={description4} onChange={(e) => { setDescription4(e.target.value) }} />
                          
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>

                    </Col>

                  </Row>
                  <Row className='mt-4'>
                    <Col>
                      <Accordion.Item eventKey="4" className='mt-4' style={{ borderTop: '1px solid #DEE2E6', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                        <Accordion.Header>Delivery Address 5 (optional)</Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-3">

                            <Form.Control type="text" placeholder="Dealer Name"
                              defaultValue={dealerName5} onChange={(e) => { setDealerName5(e.target.value) }} />

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="DO Number / Details"
                              defaultValue={doNumber5} onChange={(e) => { setDoNumber5(e.target.value) }} />

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="Full Address"
                              defaultValue={locationName5} onChange={(e) => { setLocationName5(e.target.value) }} />

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="City"
                              defaultValue={city5} onChange={(e) => { setCity5(e.target.value) }} />

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="Pincode"
                              defaultValue={pincode5} onChange={(e) => { setPincode5(e.target.value) }} />

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="State"
                              defaultValue={state5} onChange={(e) => { setState5(e.target.value) }} />

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="Mobile Number"
                              defaultValue={mobileNumber5} onChange={(e) => { setMobileNumber5(e.target.value) }} />

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="Remark"
                              defaultValue={description5} onChange={(e) => { setDescription5(e.target.value) }} />
                      
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Col>
                    <Col>
                      <Accordion.Item eventKey="5" className='mt-4' style={{ borderTop: '1px solid #DEE2E6', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                        <Accordion.Header>Delivery Address 6 (optional)</Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-3">

                            <Form.Control type="text" placeholder="Dealer Name"
                              defaultValue={dealerName6} onChange={(e) => { setDealerName6(e.target.value) }} />

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="DO Number / Details"
                              defaultValue={doNumber6} onChange={(e) => { setDoNumber6(e.target.value) }} />

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="Full Address"
                              defaultValue={locationName6} onChange={(e) => { setLocationName6(e.target.value) }} />

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="City"
                              defaultValue={city6} onChange={(e) => { setCity6(e.target.value) }} />

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="Pincode"
                              defaultValue={pincode6} onChange={(e) => { setPincode6(e.target.value) }} />

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="State"
                              defaultValue={state6} onChange={(e) => { setState6(e.target.value) }} />

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="Mobile Number"
                              defaultValue={mobileNumber6} onChange={(e) => { setMobileNumber6(e.target.value) }} />

                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="Remark"
                              defaultValue={description6} onChange={(e) => { setDescription6(e.target.value) }} />
                           
                          </Form.Group>
                        </Accordion.Body>
                      </Accordion.Item>

                    </Col>

                  </Row>
                </Accordion>
                <Row className='mt-5'>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label style={{ fontSize: '14px', fontWeight: 'bold'  }}>Bid Start time</Form.Label>
                      <DatePicker
                        inputProps={{
                          style: { padding: '15px' },
                          disabled: true,
                       
                        }}
                        value={dateValue}
                        dateFormat="DD-MM-YYYY"
                        timeFormat="hh:mm A"
                       
                      />
                    </Form.Group>

                  </Col>

                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label style={{ fontSize: '14px', fontWeight: 'bold' }}>Select Bid End time</Form.Label>
                      <DatePicker
                        inputProps={{
                          style: { padding: '15px' },

                        }}
                        value={endTime}
                        dateFormat={false}
                        timeFormat="hh:mm A"
                        // isValidDate={isValidDate} // Disable past dates
                        // isValidTime={isValidTime} // Disable past time and time before current time
                        // timeConstraints={timeConstraints} // Add constraints to the timepicker
                        onChange={(date) => setEndTime(date)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label style={{ fontSize: '14px', fontWeight: 'bold' }}>Select truck arrival cut off time</Form.Label>
                      <DatePicker
                        inputProps={{
                          style: { padding: '15px' }
                        }}
                        value={moment(truckArrival).toDate()}
                        dateFormat="DD-MM-YYYY"
                        timeFormat="hh:mm A"
                        isValidDate={isValidDate} // Disable past dates
                        isValidTime={isValidTime} // Limit time to 1 to 8 hours from the current time
                        onChange={handleDateChange2}

                      />
                    </Form.Group>
                  </Col>
                  
                  <Col>
                    <Form.Group >
                      <Form.Label style={{ fontSize: '14px', fontWeight: 'bold'  }}>Select Publish Bid to</Form.Label>
                      <div >
                        
                          <Multiselect
                            options={groupList.map(group => ({ label: group.name, value: group.id }))}
                            displayValue="label"
                          style={{ inputField: { padding: '10px' } }}
                          onSelect={handleGroupSelect} // Use the custom onSelect handler
                          onRemove={handleGroupRemove} // Use the custom onRemove handler
                          
                         
                        />
                        {groupError && (
                          <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                            {groupError}
                          </p>
                        )}
                      </div>
                    </Form.Group>
                   
                  </Col>
                </Row>
              
                <div className='d-flex justify-content-center mt-2'>
                  {loading ? <Button style={{ background: '#D5A86C', border: '#D5A86C', paddingLeft: '30px', paddingRight: '30px', }} type="submit" disabled>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"/> Sending....
                  </Button> :<Button style={{ background: '#D5A86C', border: '#D5A86C', paddingLeft: '30px', paddingRight: '30px', }} type="submit">
                    Send
                  </Button>}
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </>
  )
}

export default AddBid
