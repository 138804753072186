import React, { useState, useEffect } from 'react';

const CountDownPlus = ({ initialTime }) => {
    const [time, setTime] = useState(initialTime);

    // Check if the time format includes days
    const hasDays = initialTime.includes('days');

    useEffect(() => {
        let intervalId;

        if (!hasDays) {
            const [hours, minutes, seconds] = initialTime.split(':').map(Number);
            let totalSeconds = hours * 3600 + minutes * 60 + seconds;

            intervalId = setInterval(() => {
               
                    totalSeconds++;
                    setTime(convertSecondsToTime(totalSeconds));
               
                    // Stop the countdown when it reaches 00:00:00
                   
                
            }, 1000);
        }

        return () => clearInterval(intervalId);
    }, [initialTime, hasDays]);

    // Convert seconds back to "hh:mm:ss" format
    const convertSecondsToTime = (totalSeconds) => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    };

    return (
        <span style={{ color: 'green', fontWeight: '500' }} >{time} hr ago</span>
    );
};

export default CountDownPlus;
