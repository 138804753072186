import React from 'react'
import image from "../assets/img/logo_img.jpeg";
import success from "../assets/img/success.png";
import { useNavigate } from "react-router-dom";

const TransporterAddSuccess = () => {
  let navigate = useNavigate()

  return (
    <>
      <div className='container mt-5'>
        <div className='row d-flex justify-content-center align-items-center'>
          <div className='col-8'>
            <div className='card mt-5 border-0 shadow text-center'>
              <div className="container d-flex justify-content-center">
                <div className="img-holder">
                  <img
                    alt="logo"
                    className="img" style={{ borderRadius: "50%" }}
                    src={image}
                  />
                </div>
              </div>
              <div className='mt-3'>
                <h5 className='text-info'>Your have Signup Successfully!</h5>
                <h6 className='text-secondary'>Transaction confirm Successfully, we will
                  review your profile soon.</h6>
              </div>
              <div className="img-holder">
                <img
                  alt="logo"
                  className="imged" style={{ borderRadius: "50%" }}
                  src={success}
                />
              </div>
              <div className='mt-5'>
                <h5 className='text-black'>Welcome To Signet Industries</h5>
                <small className='text-secondary'>Completed on 7/21/2023, 4:05:03 PM</small>
              </div>
              <span className='my-3'>Get Started With</span>
              <div className='my-3'>
                <button className='btn text-white' onClick={() => { navigate('/home') }} style={{ backgroundColor: "#D5A86C" }}>Back to Dashboard</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TransporterAddSuccess
