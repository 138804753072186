import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify"
import axios from 'axios';
import { handleError } from "../Utilities/HandleError";
import { useParams } from "react-router-dom";
function TransporterUploadDocuments() {
  let navigate = useNavigate()
  //submit form
  const [loading, setLoading] = useState("")

  let [image, setImage] = useState("");
  let [image1, setImage1] = useState("");
  let [image2, setImage2] = useState("");
  let [image3, setImage3] = useState("");
  let [image4, setImage4] = useState("");

  let [sendImage, setSendImage] = useState();
  let [sendImage1, setSendImage1] = useState();
  let [sendImage2, setSendImage2] = useState();
  let [sendImage3, setSendImage3] = useState();
  let [sendImage4, setSendImage4] = useState();

  const [searchParams, setSearchparams] = useState()
  const { id } = useParams()
  useEffect(() => {
    if (id) {
      setSearchparams(id)
    }
  }, [])

  const onSubmit = () => {
    setLoading(true)
    let formData = new FormData()
    formData.append("public_id", searchParams);
    formData.append("aadhar_card_front", sendImage);
    formData.append("aadhar_card_back", sendImage1);
    formData.append("pan_card_front", sendImage2);
    formData.append("gst_certificate", sendImage3);
    formData.append("key_signature", sendImage4);

    let url = process.env.REACT_APP_BASEURL + "vendor/bank/document/";
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "multipart/form-data",
        "Authorization": `bearer ` + localStorage.getItem('adroit_token'),
      },
    };
    axios
      .post(url, formData, config)
      .then((res) => {
        setSearchparams()
        setLoading(false)
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
          theme: "colored",
        });
        if (res.data.status === 201) {
          navigate(`/transportercontactdetails/${searchParams}`)
        }
      })
      .catch((err) => {
        setLoading(false)
        const getErrRes = handleError(err);
        if (getErrRes === 401) {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        } else {
          toast.error(getErrRes ? getErrRes : "Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        }
      });
  };

  let imageHandler = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImage(reader.result);
      }
      setSendImage(e.target.files[0])
    }
    reader.readAsDataURL(e.target.files[0])
  };

  let imageHandler1 = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImage1(reader.result);
      }
      setSendImage1(e.target.files[0])
    }
    reader.readAsDataURL(e.target.files[0])
  };

  let imageHandler2 = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImage2(reader.result);
      }
      setSendImage2(e.target.files[0])
    }
    reader.readAsDataURL(e.target.files[0])
  };

  let imageHandler3 = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImage3(reader.result);
      }
      setSendImage3(e.target.files[0])
    }
    reader.readAsDataURL(e.target.files[0])
  };

  let imageHandler4 = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImage4(reader.result);
      }
      setSendImage4(e.target.files[0])
    }
    reader.readAsDataURL(e.target.files[0])
  };

  return (
    <>
      <div className="container mt-4 mb-5">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="text-center">
            <h2>Upload Documents</h2>
          </div>
          <div className="col-8">
            <div className="card p-5 mb-5">
              <form>
                <div className="container ">
                  <h6>Aadhar Card</h6>
                  <div className="card p-3 border-0 shadow">
                    <div className='row d-flex justify-content-between'>
                      <div className="col-6 text-start img-holder">
                        {image !== "" ?
                          <img
                            alt="logo"
                            className="img" style={{ borderRadius: "50%" }}
                            src={image}
                          />
                          : <img
                            src={require("../assets/img/aadhaar-card.png")}
                            alt="logo"
                            className="img" style={{ borderRadius: "50%" }}
                          />}
                      </div>
                      <div className="col-6 text-end">
                        <p className="mt-2 text-secondery">Front side of Aadhar Card</p>
                        <input type="file" accept="image/*" name="image-upload" id="input1" onChange={imageHandler} />
                        <div className="mt-1">
                          <label className="text-green-500 font-bold btn text-white pt-2" style={{ backgroundColor: "#D5A86C" }} htmlFor="input1">
                            <i className="material-icons "></i>Add Photo</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card p-3 border-0 shadow mt-3">
                    <div className='row d-flex justify-content-between'>
                      <div className="col-6 text-start img-holder">
                        {image1 !== "" ?
                          <img
                            alt="logo"
                            className="img" style={{ borderRadius: "50%" }}
                            src={image1}
                          />
                          : <img
                            src={require("../assets/img/aadhar-card-back.png")}
                            alt="logo"
                            className="img" style={{ borderRadius: "50%" }}
                          />}
                      </div>
                      <div className="col-6 text-end">
                        <p className="mt-2 text-secondery">Back side of Aadhar Card</p>
                        <input type="file" accept="image/*" name="image-upload1" id="input2" onChange={imageHandler1} />
                        <div className="mt-1">
                          <label className="text-green-500 font-bold btn text-white pt-2" style={{ backgroundColor: "#D5A86C" }} htmlFor="input2">
                            <i className="material-icons "></i>Add Photo</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h6 className="mt-3">Pan Card</h6>
                  <div className="card p-3 border-0 shadow mt-3">
                    <div className='row d-flex justify-content-between'>
                      <div className="col-6 text-start img-holder">
                        {image2 !== "" ?
                          <img
                            alt="logo"
                            className="img" style={{ borderRadius: "50%" }}
                            src={image2}
                          />
                          : <img
                            src={require("../assets/img/pan-card.webp")}
                            alt="logo"
                            className="img" style={{ borderRadius: "50%" }}
                          />}
                      </div>
                      <div className="col-6 text-end">
                        <p className="mt-2 text-secondery">Front side of Pan Card</p>
                        <input type="file" accept="image/*" name="image-upload" id="input3" onChange={imageHandler2} />
                        <div className="mt-1">
                          <label className="text-green-500 font-bold btn text-white pt-2" style={{ backgroundColor: "#D5A86C" }} htmlFor="input3">
                            <i className="material-icons "></i>Add Photo</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h6 className="mt-3">GST Certificate</h6>
                  <div className="card p-3 border-0 shadow mt-3">
                    <div className='row d-flex justify-content-between'>
                      <div className="col-6 text-start img-holder">
                        {image3 !== "" ?
                          <img
                            alt="logo"
                            className="img" style={{ borderRadius: "50%" }}
                            src={image3}
                          />
                          : <img
                            src={require("../assets/img/gst-certificate.jpg")}
                            alt="logo"
                            className="img" style={{ borderRadius: "50%" }}
                          />}
                      </div>
                      <div className="col-6 text-end">
                        <p className="mt-2 text-secondery">Upload GST Certificate</p>
                        <input type="file" accept="image/*" name="image-upload" id="input4" onChange={imageHandler3} />
                        <div className="mt-1">
                          <label className="text-green-500 font-bold btn text-white pt-2" style={{ backgroundColor: "#D5A86C" }} htmlFor="input4">
                            <i className="material-icons "></i>Add Photo</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h6 className="mt-3">Key Signature</h6>
                  <div className="card p-3 border-0 shadow mt-3">
                    <div className='row d-flex justify-content-between'>
                      <div className="col-6 text-start img-holder">
                        {image4 !== "" ?
                          <img
                            alt="logo"
                            className="img" style={{ borderRadius: "50%" }}
                            src={image4}
                          />
                          : <img
                            src={require("../assets/img/fake-signature.jpg")}
                            alt="logo"
                            className="img" style={{ borderRadius: "50%" }}
                          />}
                      </div>
                      <div className="col-6 text-end">
                        <p className="mt-2 text-secondery">Key Signature</p>
                        <input type="file" accept="image/*" name="image-upload" id="input5" onChange={imageHandler4} />
                        <div className="mt-1">
                          <label className="text-green-500 font-bold btn text-white pt-2" style={{ backgroundColor: "#D5A86C" }} htmlFor="input5">
                            <i className="material-icons "></i>Add Photo</label>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-4">
                  <button style={{ backgroundColor: "#595A6F" }} className="btn w-25 text-white" onClick={() => { navigate('/transporterdetail') }}>Prev</button>
                  {loading ? <button onClick={onSubmit} className='btn w-25 text-white' style={{ backgroundColor: "#D5A86C" }} disabled> <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Next...</button> :
                    <button onClick={onSubmit} className='btn w-25 text-white' style={{ backgroundColor: "#D5A86C" }}>Next</button>}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TransporterUploadDocuments;
