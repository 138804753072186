import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MultiSelect from 'multiselect-react-dropdown';
import { toast } from "react-toastify"
import axios from 'axios';
import { handleError } from "../Utilities/HandleError";
import { useForm } from 'react-hook-form';

function TransporterDetail() {
  let navigate = useNavigate();
  useEffect(() => {
    getbranchList();
    getvehicleList();
    getgroupList();
  }, []);
  // Branch 
  const [branchList, setBranchList] = useState([]);
  const [selectedbranch, setSelectedbranch] = useState([]);
  const optionsBranch = branchList;
  const onSelectbranch = (selectedList, selectedItem) => {
    setSelectedbranch(selectedList);
  };
  const onRemovebranch = (selectedList, removedItem) => {
    setSelectedbranch(selectedList);
  };
  const getbranchList = () => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
        "Authorization": `bearer ` + localStorage.getItem('adroit_token'),
      }
    };
    let url = process.env.REACT_APP_BASEURL + "branchList/";
    axios.get(url, config)
      .then((res) => {
        setBranchList(res.data.data)
      })
      .catch(err => {
        const getErrRes = handleError(err);
        if (getErrRes === 401) {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        } else {
          toast.error(getErrRes ? getErrRes : "Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        }
      }
      )
  }
  // Vehcile
  const [vehicleList, setVehicleList] = useState([]);
  const [selectedvehicle, setSelectedvehicle] = useState([]);
  const optionsVehicle = vehicleList;
  const onSelectvehicle = (selectedList, selectedItem) => {
    setSelectedvehicle(selectedList);
  };
  const onRemovevehicle = (selectedList, removedItem) => {
    setSelectedvehicle(selectedList);
  };
  const getvehicleList = () => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
        "Authorization": `bearer ` + localStorage.getItem('adroit_token'),
      }
    };
    let url = process.env.REACT_APP_BASEURL + "vehicle/";
    axios.get(url, config)
      .then((res) => {
        setVehicleList(res.data.data)
      })
      .catch(err => {
        const getErrRes = handleError(err);
        if (getErrRes === 401) {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        } else {
          toast.error(getErrRes ? getErrRes : "Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        }
      }
      )
  }
  //Group
  const [groupList, setGroupList] = useState([]);
  const [selectedgroup, setSelectedgroup] = useState([]);
  const optionsGroup = groupList;
  const onSelectgroup = (selectedList, selectedItem) => {
    setSelectedgroup(selectedList);
  };
  const onRemovegroup = (selectedList, removedItem) => {
    setSelectedgroup(selectedList);
  };
  const getgroupList = () => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
        "Authorization": `bearer ` + localStorage.getItem('adroit_token'),
      }
    };
    let url = process.env.REACT_APP_BASEURL + "publish/group/";
    axios.get(url, config)
      .then((res) => {
        setGroupList(res.data.data)
      })
      .catch(err => {
        const getErrRes = handleError(err);
        if (getErrRes === 401) {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        } else {
          toast.error(getErrRes ? getErrRes : "Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        }
      }
      )
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  //submit form
  const [loading, setLoading] = useState("")
  let [image, setImage] = useState("");
  let [sendImage, setSendImage] = useState();
  const onSubmit = (data) => {
    setLoading(true)
    // For Branch
    const branhArr = [];
    selectedbranch.map((item) => {
      branhArr.push((item.public_id).toString())
    })
    // For Vehicle
    const vehcileArr = [];
    selectedvehicle.map((item) => {
      vehcileArr.push((item.public_id).toString())
    })
    // For group
    const groupArr = [];
    selectedgroup.map((item) => {
      groupArr.push((item.id).toString())
    })
    let formData = new FormData()
    formData.append("image", sendImage);
    formData.append("branch", branhArr);
    formData.append("vehicle", vehcileArr);
    formData.append("transporter_name", data.transporter_name);
    formData.append("aadhar_number", data.aadhar_number);
    formData.append("pan_number", data.pan_number);
    formData.append("gst_number", data.gst_number);
    formData.append("key_signature", data.key_signature);
    formData.append("establishment_year", data.establishment_year);
    formData.append("groups", groupArr);

    let url = process.env.REACT_APP_BASEURL + "vendor/signup/";
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "multipart/form-data",
        "Authorization": `bearer ` + localStorage.getItem('adroit_token'),
      },
    };
    axios
      .post(url, formData, config)
      .then((res) => {
        setLoading(false)
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
          theme: "colored",
        });
        if (res.data.status === 201) {
          navigate(`/transportdocupdate/${res.data.data.public_id}`,)
        }
      })
      .catch((err) => {
        setLoading(false)
        const getErrRes = handleError(err);
        if (getErrRes === 401) {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        } else {
          toast.error(getErrRes ? getErrRes : "Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        }
      });
  };

  let imageHandler = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImage(reader.result);
      }
      setSendImage(e.target.files[0])
    }
    reader.readAsDataURL(e.target.files[0])
  };

  return (
    <>
      <div className="container mb-5 mt-4">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="text-center">
            <h2>Transporter Detail</h2>
          </div>
          <div className="col-8">
            <div className="card p-5 mb-5">
              <form>
                <div className="container ">
                  <div className='d-flex justify-content-center'>
                    <div className="img-holder">
                      {image !== "" ?
                        <img
                          alt="logo"
                          className="img" style={{ borderRadius: "50%" }}
                          src={image}
                        />
                        : <img
                          src={require("../assets/img/placeholder.jpeg")}
                          alt="logo"
                          className="img" style={{ borderRadius: "50%" }}
                        />}
                    </div>
                  </div>
                  <input type="file" accept="image/*" name="image-upload" id="input" onChange={imageHandler} />
                  <div className="label">
                    <label className="image-upload text-green-500 font-bold" htmlFor="input">
                      <i className="material-icons "></i>Choose your Photo</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 mb-3">
                    <label for="exampleInputEmail1" className="form-label">Signet Branch For</label>
                    <MultiSelect
                      options={optionsBranch}
                      selectedValues={selectedbranch}
                      onSelect={onSelectbranch}
                      onRemove={onRemovebranch}
                      displayValue="name"
                      name="branch"
                    />
                  </div>
                  <div className="col-6 mb-3">
                    <label for="exampleInputPassword1" className="form-label">Vehicle Type</label>
                    <MultiSelect
                      options={optionsVehicle}
                      selectedValues={selectedvehicle}
                      onSelect={onSelectvehicle}
                      onRemove={onRemovevehicle}
                      displayValue="name"
                      name="vehicle"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 mb-3">
                    <label for="exampleInputPassword1" className="form-label">Transporter Name</label>
                    <input type="text" name="transporter_name"
                      {...register("transporter_name", { required: true })}
                      placeholder="Transporter Name"
                      className="form-control" />
                    {errors?.transporter_name?.type === "required" && (
                      <p className="text-danger">
                        Transporter Name is required!
                      </p>
                    )}
                  </div>
                  <div className="col-6 mb-3">
                    <label for="exampleInputPassword1" className="form-label">Aadhaar Card Number</label>
                    <input type="text" name="aadhar_number" maxLength={12}
                      {...register("aadhar_number", { required: true })}
                      placeholder="Aadhaar card number"
                      className="form-control" />
                    {errors?.aadhar_number?.type === "required" && (
                      <p className="text-danger">
                        Aadhar number is required!
                      </p>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 mb-3">
                    <label for="exampleInputPassword1" className="form-label">Pan Number</label>
                    <input type="text" name="pan_number"
                      {...register("pan_number", {
                        required: true, pattern:
                          /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/
                      })}
                      placeholder="Pan card"
                      className="form-control" />
                    {errors?.pan_number?.type === "required" && (
                      <p className="text-danger">
                        Pan Number is required!
                      </p>
                    )}
                    {errors.pan_number?.type === "pattern" && (
                      <p className="text-danger">
                        Please enter a valid pancard number!</p>)}
                  </div>
                  <div className="col-6 mb-3">
                    <label for="exampleInputPassword1" className="form-label">GST Number</label>
                    <input type="text" name="gst_number"
                      {...register("gst_number", {
                        required: true, pattern:
                          /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
                      })}
                      placeholder="GST Number"
                      className="form-control" />
                    {errors?.gst_number?.type === "required" && (
                      <p className="text-danger">
                        GST Number is required!
                      </p>
                    )}
                    {errors.gst_number?.type === "pattern" && (
                      <p className="text-danger">
                        Please enter a valid GST number!</p>)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 mb-3">
                    <label for="exampleInputPassword1" className="form-label">Key Signature Name</label>
                    <input type="text"
                      name="key_signature"
                      {...register("key_signature", {
                        required: true,
                      })}
                      placeholder="Key Signature" className="form-control" />
                    {errors?.key_signature?.type === "required" && (
                      <p className="text-danger">
                        Key Signature Name is required!
                      </p>
                    )}
                  </div>
                  <div className="col-6 mb-3">
                    <label for="exampleInputPassword1" className="form-label">Year of Establishment</label>
                    <input type="text" name="establishment_year"
                      maxLength={4}
                      {...register("establishment_year", {
                        required: true,
                      })}
                      placeholder="Year of Establishment"
                      className="form-control" />
                    {errors?.establishment_year?.type === "required" && (
                      <p className="text-danger">
                        Year of Establishment is required!
                      </p>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col mb-3">
                    <label for="exampleInputPassword1" className="form-label">Add in Group</label>
                    <MultiSelect
                      options={optionsGroup}
                      selectedValues={selectedgroup}
                      onSelect={onSelectgroup}
                      onRemove={onRemovegroup}
                      name="group"
                      displayValue="name"
                    />
                  </div>
                </div>
                <div className="text-center mt-4">
                  {loading ? <button onClick={handleSubmit(onSubmit)} className='btn w-25 text-white' style={{ backgroundColor: "#D5A86C" }} disabled> <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Next...</button> :
                    <button onClick={handleSubmit(onSubmit)} className='btn w-25 text-white' style={{ backgroundColor: "#D5A86C" }}>Next</button>}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TransporterDetail;
