import React, { useState, useEffect } from "react";
import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';

function TimeDate() {
    const currentDate = moment();
    const [endTime, setEndTime] = useState(moment());
    const [maxTime, setMaxTime] = useState(moment(currentDate).add(8, 'hours'));

    const calculateMaximumDate = () => {
        setEndTime((prevEndTime) => {
            if (prevEndTime.isAfter(maxTime)) {
                return maxTime;
            }
            return prevEndTime;
        });
    };

    useEffect(() => {
        calculateMaximumDate();
    }, [maxTime]);

    const updateMaxTime = () => {
        setMaxTime(moment(currentDate).add(8, 'hours'));
    };

    useEffect(() => {
        const interval = setInterval(updateMaxTime, 1000); // Update maxTime every second
        return () => clearInterval(interval);
    }, [currentDate]);

    const isValidDate = (current) => {
        return current.isAfter(currentDate, 'day');
    };

    const isValidTime = (current) => {
        const now = moment();
        return current.isSameOrAfter(now, 'minute') && current.isBefore(maxTime);
    };
    const timeConstraints = {
        hours: { min: moment().hour(), max: maxTime.hour(), step: 1 },
        minutes: { min: moment().minute(), max: maxTime.minute(), step: 1 },
        seconds: { min: moment().second(), max: maxTime.second(), step: 1 },
        milliseconds: { min: moment().millisecond(), max: maxTime.millisecond(), step: 100 },
    };

    return (
        <>
            <DatePicker
                value={endTime}
                dateFormat={false}
                timeFormat="hh:mm A"
                isValidDate={isValidDate} // Disable past dates
                isValidTime={isValidTime} // Disable past time and time before current time
                timeConstraints={timeConstraints} // Add constraints to the timepicker
                onChange={(date) => setEndTime(date)}
            />
        </>
    );
}

export default TimeDate;
