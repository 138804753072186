import { useEffect, useState } from "react";
import Accordion from 'react-bootstrap/Accordion';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify"
import axios from 'axios';
import { handleError } from "../Utilities/HandleError";
import { useParams } from "react-router-dom";
import { useForm } from 'react-hook-form';

function TransporterContactDetails() {
  let navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues
  } = useForm();
  //sumbit 
  const [loading, setLoading] = useState("")
  const [searchParams, setSearchparams] = useState()
  const { id } = useParams()
  useEffect(() => {
    if (id) {
      setSearchparams(id)
    }
  }, [])
  const onSubmit = (data) => {
    console.log(data)
    setLoading(true)
    let body =
    {
      address_list: [
        {
          contact_person_name: data.contact_person_name,
          contact_number: data.contact_number,
          email: data.email,
          address: data.address,
          land_mark: data.land_mark,
          address_type: "head_office",
          city: data.city,
          pin_code: data.pin_code,
        },
        {
          contact_person_name: data.contact_person_name1 ? data.contact_person_name1 : document.getElementById("contact_person_name1").value,
          contact_number: data.contact_number1 ? data.contact_number1 : document.getElementById("contact_number1").value,
          email: data.email1 ? data.email1 : document.getElementById("email1").value,
          address: data.address1 ? data.address1 : document.getElementById("address1").value,
          land_mark: data.land_mark1 ? data.land_mark1 : document.getElementById("land_mark1").value,
          address_type: "branch_office",
          city: data.city1 ? data.city1 : document.getElementById("city1").value,
          pin_code: data.pin_code1 ? data.pin_code1 : document.getElementById("pin_code1").value,
        },
        {
          contact_person_name: data.contact_person_name2 ? data.contact_person_name2 : document.getElementById("contact_person_name2").value,
          contact_number: data.contact_number2 ? data.contact_number2 : document.getElementById("contact_number2").value,
          email: data.email2 ? data.email2 : document.getElementById("email2").value,
          address: data.address2 ? data.address2 : document.getElementById("address2").value,
          land_mark: data.land_mark2 ? data.land_mark2 : document.getElementById("land_mark2").value,
          address_type: "registration_office",
          city: data.city2 ? data.city2 : document.getElementById("city2").value,
          pin_code: data.pin_code2 ? data.pin_code2 : document.getElementById("pin_code2").value,
        }
      ], public_id: searchParams
    }
    let url = process.env.REACT_APP_BASEURL + "vendor/contact/detail/";
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
        "Authorization": `bearer ` + localStorage.getItem('adroit_token'),
      },
    };
    axios
      .post(url, body, config)
      .then((res) => {
        setLoading(false)
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
          theme: "colored",
        });
        if (res.data.status === 201) {
          navigate(`/transporterbankdetails/${searchParams}`,)
        }
      })
      .catch((err) => {
        setLoading(false)
        const getErrRes = handleError(err);
        if (getErrRes === 401) {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        } else {
          toast.error(getErrRes ? getErrRes : "Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
            theme: "colored",
          });
        }
      });
  };
  const FillAddressInput = (e) => {
    let contact_person_name = document.getElementById("contact_person_name");
    let contact_number = document.getElementById("contact_number");
    let email = document.getElementById("email");
    let address = document.getElementById("address");
    let land_mark = document.getElementById("land_mark");
    let city = document.getElementById("city");
    let pin_code = document.getElementById("pin_code");
    let contact_person_name1 = document.getElementById("contact_person_name1");
    let contact_number1 = document.getElementById("contact_number1");
    let email1 = document.getElementById("email1");
    let address1 = document.getElementById("address1");
    let land_mark1 = document.getElementById("land_mark1");
    let city1 = document.getElementById("city1");
    let pin_code1 = document.getElementById("pin_code1");
    if (e) {
      let pAddressLine1Value = contact_person_name.value;
      let pAddressLine2Value = contact_number.value;
      let pLandmarkValue = email.value;
      let pZipcodeValue = address.value;
      let pCityValue = land_mark.value;
      let pStateValue = city.value;
      let pCountryValue = pin_code.value;
      contact_person_name1.value = pAddressLine1Value;
      contact_number1.value = pAddressLine2Value;
      email1.value = pLandmarkValue;
      address1.value = pZipcodeValue;
      land_mark1.value = pCityValue;
      city1.value = pStateValue;
      pin_code1.value = pCountryValue;
    }
    else {
      contact_person_name1.value = "";
      contact_number1.value = "";
      email1.value = "";
      address1.value = "";
      land_mark1.value = "";
      city1.value = "";
      pin_code1.value = "";
    }
  }
  const FillAddressInput1 = (e) => {
    let contact_person_name = document.getElementById("contact_person_name");
    let contact_number = document.getElementById("contact_number");
    let email = document.getElementById("email");
    let address = document.getElementById("address");
    let land_mark = document.getElementById("land_mark");
    let city = document.getElementById("city");
    let pin_code = document.getElementById("pin_code");
    let contact_person_name2 = document.getElementById("contact_person_name2");
    let contact_number2 = document.getElementById("contact_number2");
    let email2 = document.getElementById("email2");
    let address2 = document.getElementById("address2");
    let land_mark2 = document.getElementById("land_mark2");
    let city2 = document.getElementById("city2");
    let pin_code2 = document.getElementById("pin_code2");
    if (e) {
      let pAddressLine1Value = contact_person_name.value;
      let pAddressLine2Value = contact_number.value;
      let pLandmarkValue = email.value;
      let pZipcodeValue = address.value;
      let pCityValue = land_mark.value;
      let pStateValue = city.value;
      let pCountryValue = pin_code.value;

      contact_person_name2.value = pAddressLine1Value;
      contact_number2.value = pAddressLine2Value;
      email2.value = pLandmarkValue;
      address2.value = pZipcodeValue;
      land_mark2.value = pCityValue;
      city2.value = pStateValue;
      pin_code2.value = pCountryValue;
    }
    else {
      contact_person_name2.value = "";
      contact_number2.value = "";
      email2.value = "";
      address2.value = "";
      land_mark2.value = "";
      city2.value = "";
      pin_code2.value = "";
    }
  }

  return (
    <>
      <div className="container mb-5 mt-4">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="text-center">
            <h2>Transporter Contact Details</h2>
          </div>
          <div className="col-8">
            <div className="card p-5 mb-5">
              <form>
                <div className="pb-3"><h5>Head Office</h5></div>
                <div className="row">
                  <div className="col-6 mb-3">
                    <label className="form-label">Contact Person Name</label>
                    <input type="text" name="contact_person_name" id="contact_person_name"
                      {...register("contact_person_name", { required: true })}
                      placeholder="Contact Person Name"
                      className="form-control" />
                    {errors.contact_person_name?.type === "required" && (
                      <p className="text-danger">
                        Person Name is required!
                      </p>
                    )}
                  </div>
                  <div className="col-6 mb-3">
                    <label className="form-label">Mobile Number</label>
                    <input type="text" maxLength={10} name="contact_number" id="contact_number"
                      {...register("contact_number", { required: true })}
                      placeholder="Mobile Number"
                      className="form-control" />
                    {errors?.contact_number?.type === "required" && (
                      <p className="text-danger">
                        Mobile Number is required!
                      </p>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 mb-3">
                    <label className="form-label">Email Id/ User Id</label>
                    <input type="text" name="email" id="email"
                      {...register("email", { required: true, pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ })}
                      placeholder="Email Id/ User Id"
                      className="form-control" />
                    {errors?.email?.type === "required" && (
                      <p className="text-danger">
                        Email Id is required!
                      </p>
                    )}
                    {errors?.email?.type === "pattern" && (
                      <p className="text-danger">
                        Invalid email address
                      </p>
                    )}
                  </div>
                  <div className="col-6 mb-3">
                    <label className="form-label">Landmark</label>
                    <input type="text" name="land_mark" id="land_mark"
                      {...register("land_mark", { required: true })}
                      placeholder="Landmark"
                      className="form-control" />
                    {errors?.land_mark?.type === "required" && (
                      <p className="text-danger">
                        Landmark is required!
                      </p>
                    )}
                  </div>
                </div>
                <div className="mb-3">
                  <label className="form-label">Enter Full Address</label>
                  <textarea className="form-control" placeholder="Enter Full Address" name="address"
                    id="address"
                    {...register("address", { required: true })}></textarea>
                  {errors?.address?.type === "required" && (
                    <p className="text-danger">
                      Full Address is required!
                    </p>
                  )}
                </div>
                <div className="row">
                  <div className="col-6 mb-3">
                    <label className="form-label">City</label>
                    <input type="text" name="city" id="city"
                      {...register("city", { required: true })}
                      placeholder="City"
                      className="form-control" />
                    {errors?.city?.type === "required" && (
                      <p className="text-danger">
                        City is required!
                      </p>
                    )}
                  </div>
                  <div className="col-6 mb-3">
                    <label className="form-label">City Pincode</label>
                    <input type="text" name="pin_code" id="pin_code"
                      {...register("pin_code", { required: true })}
                      placeholder="City Pincode"
                      className="form-control" />
                    {errors?.pin_code?.type === "required" && (
                      <p className="text-danger">
                        City Pincode is required!
                      </p>
                    )}
                  </div>
                </div>
                <div className="mt-3">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Branch Office</Accordion.Header>
                      <Accordion.Body>
                        <div className="row">
                          <div className="col-6 mb-3">
                            <label className="form-label">Contact Person Name</label>
                            <input type="text" name="contact_person_name1" id="contact_person_name1"
                              {...register("contact_person_name1", { required: false })}
                              placeholder="Contact Person Name"
                              className="form-control"
                              onChange={(e) => { console.log(e.target.value) }}
                            />
                            {errors?.contact_person_name1?.type === "required" && (
                              <p className="text-danger">
                                Person Name is required!
                              </p>
                            )}
                          </div>
                          <div className="col-6 mb-3">
                            <label className="form-label">Mobile Number</label>
                            <input type="text" maxLength={10} name="contact_number1" id="contact_number1"
                              {...register("contact_number1", { required: false })}
                              placeholder="Mobile Number"
                              className="form-control" />
                            {errors?.contact_number1?.type === "required" && (
                              <p className="text-danger">
                                Mobile Number is required!
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6 mb-3">
                            <label className="form-label">Email Id/ User Id</label>
                            <input type="text" name="email1" id="email1"
                              {...register("email1", { required: false, pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ })}
                              placeholder="Email Id/ User Id"
                              className="form-control" />
                            {errors?.email1?.type === "required" && (
                              <p className="text-danger">
                                Email Id is required!
                              </p>
                            )}
                            {errors?.email1?.type === "pattern" && (
                              <p className="text-danger">
                                Invalid email address
                              </p>
                            )}
                          </div>
                          <div className="col-6 mb-3">
                            <label className="form-label">Landmark</label>
                            <input type="text" name="land_mark1" id="land_mark1"
                              {...register("land_mark1", { required: false })}
                              placeholder="Landmark"
                              className="form-control" />
                            {errors?.land_mark1?.type === "required" && (
                              <p className="text-danger">
                                Landmark is required!
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Enter Full Address</label>
                          <textarea className="form-control" placeholder="Enter Full Address" name="address1"
                            id="address1"
                            {...register("address1", { required: false })}></textarea>
                          {errors?.address1?.type === "required" && (
                            <p className="text-danger">
                              Full Address is required!
                            </p>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-6 mb-3">
                            <label className="form-label">City</label>
                            <input type="text" name="city1" id="city1"
                              {...register("city1", { required: false })}
                              placeholder="City"
                              className="form-control" />
                            {errors?.city1?.type === "required" && (
                              <p className="text-danger">
                                City is required!
                              </p>
                            )}
                          </div>
                          <div className="col-6 mb-3">
                            <label className="form-label">City Pincode</label>
                            <input type="text" name="pin_code1" id="pin_code1"
                              {...register("pin_code1", { required: false })}
                              placeholder="City Pincode"
                              className="form-control" />
                            {errors?.pin_code1?.type === "required" && (
                              <p className="text-danger">
                                City Pincode is required!
                              </p>
                            )}
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <div className="mb-3 mt-2 form-check">
                    <input type="checkbox" className="form-check-input" id="exampleCheck1"
                      onClick={(e) => {
                        FillAddressInput(e.target
                          .checked)
                      }}
                    />
                    <label className="form-check-label" htmlFor="exampleCheck1">Same as Head Office</label>
                  </div>
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Registration Office</Accordion.Header>
                      <Accordion.Body>
                        <div className="row">
                          <div className="col-6 mb-3">
                            <label className="form-label">Contact Person Name</label>
                            <input type="text" name="contact_person_name2" id="contact_person_name2"
                              {...register("contact_person_name2", { required: false })}
                              placeholder="Contact Person Name"
                              className="form-control" />
                            {errors?.contact_person_name2?.type === "required" && (
                              <p className="text-danger">
                                Person Name is required!
                              </p>
                            )}
                          </div>
                          <div className="col-6 mb-3">
                            <label className="form-label">Mobile Number</label>
                            <input type="text" maxLength={10} name="contact_number2" id="contact_number2"
                              {...register("contact_number2", { required: false })}
                              placeholder="Mobile Number"
                              className="form-control" />
                            {errors?.contact_number2?.type === "required" && (
                              <p className="text-danger">
                                Mobile Number is required!
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6 mb-3">
                            <label className="form-label">Email Id/ User Id</label>
                            <input type="text" name="email2" id="email2"
                              {...register("email2", { required: false, pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ })}
                              placeholder="Email Id/ User Id"
                              className="form-control" />
                            {errors?.email2?.type === "required" && (
                              <p className="text-danger">
                                Email Id is required!
                              </p>
                            )}
                            {errors?.email2?.type === "pattern" && (
                              <p className="text-danger">
                                Invalid email address
                              </p>
                            )}
                          </div>
                          <div className="col-6 mb-3">
                            <label className="form-label">Landmark</label>
                            <input type="text" name="land_mark2" id="land_mark2"
                              {...register("land_mark2", { required: false })}
                              placeholder="Landmark"
                              className="form-control" />
                            {errors?.land_mark2?.type === "required" && (
                              <p className="text-danger">
                                Landmark is required!
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Enter Full Address</label>
                          <textarea className="form-control" placeholder="Enter Full Address" name="address2" id="address2"
                            {...register("address2", { required: false })}></textarea>
                          {errors?.address2?.type === "required" && (
                            <p className="text-danger">
                              Full Address is required!
                            </p>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-6 mb-3">
                            <label className="form-label">City</label>
                            <input type="text" name="city2" id="city2"
                              {...register("city2", { required: false })}
                              placeholder="City"
                              className="form-control" />
                            {errors?.city2?.type === "required" && (
                              <p className="text-danger">
                                City is required!
                              </p>
                            )}
                          </div>
                          <div className="col-6 mb-3">
                            <label className="form-label">City Pincode</label>
                            <input type="text" name="pin_code2" id="pin_code2"
                              {...register("pin_code2", { required: false })}
                              placeholder="City Pincode"
                              className="form-control" />
                            {errors?.pin_code2?.type === "required" && (
                              <p className="text-danger">
                                City Pincode is required!
                              </p>
                            )}
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <div className="mb-3 mt-2 form-check">
                    <input type="checkbox" className="form-check-input" id="exampleCheck2"
                      onClick={(e) => { FillAddressInput1(e.target.checked) }}
                    />
                    <label className="form-check-label" htmlFor="exampleCheck2">Same as Head Officer</label>
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-4">
                  <button style={{ backgroundColor: "#595A6F" }} className="btn w-25 text-white" onClick={() => { navigate('/transportdocupdate') }}>Prev</button>
                  {loading ? <button onClick={handleSubmit(onSubmit)} className='btn w-25 text-white' style={{ backgroundColor: "#D5A86C" }} disabled> <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Next...</button> :
                    <button onClick={handleSubmit(onSubmit)} className='btn w-25 text-white' style={{ backgroundColor: "#D5A86C" }}>Next</button>}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TransporterContactDetails;
