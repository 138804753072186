import React from 'react';
import * as XLSX from 'xlsx'; // Update the import
import { FaFileDownload } from "react-icons/fa";
import { saveAs } from 'file-saver';

function ExcelDownloadButton({ data, load }) {
    const handleDownload = () => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(excelBlob, 'data.xlsx');
    };

    return (
        <div className='p-3'>
            <button onClick={handleDownload} className='btn text-white ' style={{ backgroundColor: "#249B3E" }} > <FaFileDownload className='text-white' /> Download Excel</button>
        </div>
    );
}

export default ExcelDownloadButton;
